<template>
  <div class="">
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="message..."
        v-model="message">
      <div class="input-group-append pointer" @click="speakDevice">
        <span class="input-group-text text-warning">Test Speak</span>
      </div>
      <div class="input-group-append pointer" @click="bellDevice">
        <span class="input-group-text text-success">Ring Bell</span>
      </div>
    </div>

    <div v-if="isLoaded" class="mt-3">
      [ STATUS ]

      <div v-if="errorMessage">
        {{errorMessage}}
      </div>

      <ul class="list-unstyled" v-else>
        <li>Tag: {{status.tag}}</li>
        <li>Audio Address: {{status.audioAddress}}</li>
        <li>Token: {{status.token}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DETAIL_STATUS,
  SPEAK_DEVICE,
  BELL_DEVICE
} from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isLoaded: false,
      errorMessage: '',
      status: {
        tag: '',
        audioAddress: '',
        token: '',
      },
      message: ''
    }
  },
  methods: {
    fetchData () {
      this.isLoaded = false
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        Object.keys(this.status).forEach(key => {
          this.status[key] = status[key]
        })
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    },
    speakDevice () {
      this.$apollo.mutate({
        mutation: SPEAK_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          message: this.message
        }
      }).then(() => {
        this.$toasted.global.success('ส่งคำสั่งไปยังอุปกรณ์')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
    bellDevice () {
      this.$apollo.mutate({
        mutation: BELL_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        }
      }).then(() => {
        this.$toasted.global.success('ส่งคำสั่งไปยังอุปกรณ์')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
